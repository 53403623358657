import React, { memo } from 'react';

import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import SectionHeadline from 'components/elements/SectionHeadline';

import EventListItem from 'components/blocks/EventListItem';


const OnTour = ({
  events,
  dispatch,
  onOnTourPage
}) => {


  return (
    <Container onOnTourPage={onOnTourPage}>
      <Headline onOnTourPage={onOnTourPage}>On Tour</Headline>
      <EventList>
        {
          events.map((event) => (
            <EventListItem onOnTourPage={onOnTourPage} event={event} key={event.title}/>
          ))
        }
      </EventList>
      {
        !onOnTourPage && (
          <CtaLink to="/on-tour">View All Events</CtaLink>
        )
      }
    </Container>
  )
};

OnTour.propTypes = {
  mapIsOpen: PropTypes.bool
};

export default OnTour;


const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.Orange};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 4em;
  /* @media all and (min-width: 700px) {
    background-color: white;
  } */
  @media all and (min-width: 750px) {
    padding-bottom: 10em;
  }
  ${( { onOnTourPage } ) =>
    onOnTourPage &&
    css`
      background-color: white;
    `
  };
`;

const Headline = styled(SectionHeadline)`
  margin: 2em 0;
  ${( { onOnTourPage } ) =>
    onOnTourPage &&
    css`
      background-color: white;
      padding: 3em 4em;
      &:before {
        top: 30%;
        left: 20%;
        background-image: url('/icons/left-headline-arrow-primary.svg');
      }
      &:after {
        bottom: 32.5%;
        background-image: url('/icons/right-headline-arrow-primary.svg');
      }
      h2 {
        color: ${({ theme }) => theme.colors.Primary};
      }
    `
  };
  /* @media all and (min-width: 700px) {
    background-color: white;
    padding: 3em 4em;
    &:before {
      top: 30%;
      left: 20%;
      background-image: url('/icons/left-headline-arrow-primary.svg');
    }
    &:after {
      bottom: 32.5%;
      background-image: url('/icons/right-headline-arrow-primary.svg');
    }
    h2 {
      color: ${({ theme }) => theme.colors.Primary};
    }
    
  } */

  @media all and (min-width: 900px) {
    h2 {
      font-size: 4em;
    }
  }
`;

const EventList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1em;
  max-width: 850px;
  li {
    margin: 1em 0;
  }
  @media all and (min-width: 700px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    li {
      width: 48.5%;
    }
  }
  ${( { onOnTourPage } ) =>
    onOnTourPage &&
    css`
      @media all and (min-width: 1024px) {
        li {
          width: 31%;
        }
      }
    `
  };
`;

const CtaLink = styled(Link)`
  font-family: 'Bebas Neue', serif;
  font-size: 2.4em;
  color: ${({ theme }) => theme.colors.Primary};
  border: 2px solid ${({ theme }) => theme.colors.Primary};
  align-self: center;
  padding: 0.2em 1em;
  padding-top: 0.35em;
  margin-top: 1.5em;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.Primary};
    color: ${({ theme }) => theme.colors.Orange}
  }
`;