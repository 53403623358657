import React from 'react';

import styled, { css } from 'styled-components';


const EventListItem = ({
  event,
  onOnTourPage
}) => {

  const formattedStart = new Date(event.startDate);

  return (
    <Container onOnTourPage={onOnTourPage}>
      <Columns>
        <DateWrapper onOnTourPage={onOnTourPage}>
          <p className={'bebas'}>{formattedStart.getDate()}</p>
          <p>{formattedStart.toLocaleString('default', { month: 'short' })}</p>
        </DateWrapper>
        {
          event.eventLink && (
            <StyledLink onOnTourPage={onOnTourPage} href={event.eventLink} target="_blank" rel="noopener noreferrer">
              View Event
            </StyledLink>
          )
        }
      </Columns>
      <Columns>
        <DetailsWrapper onOnTourPage={onOnTourPage}>
          <p className={'bebas'}>{event.title}</p>
          <p className={'bebas'}>{event.locationName}</p>
        </DetailsWrapper>
      </Columns>
    </Container>
  )
};

EventListItem.propTypes = {

};

export default EventListItem;

const Container = styled.li`
  display: flex;
  padding-top: 3em;
  border: 2px solid white;
  justify-content: center;

  ${( { onOnTourPage } ) =>
    onOnTourPage &&
    css`
      background-color: white;
      border-color: ${({ theme }) => theme.colors.Primary};
    `
  };
`;

const Columns =  styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:first-of-type {
    padding-left: 2em;
  }
  &:last-of-type {
  }
  @media all and (max-width: 700px) {
    li:nth-of-type(even) & {
      &:first-of-type {
        order: 2;
      }
      &:last-of-type {
        order: 1;
      }
    }
  }
`;

const StyledLink = styled.a`
  color: white;
  background-color: #a67c12;
  font-family: 'Bebas Neue', serif;
  border: 2px solid white;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.4em;
  letter-spacing: 2px;
  padding: 3px 10px;
  margin-bottom: -2px;
  min-width: 140px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: white;
    color: #a67c12;
  }
  ${( { onOnTourPage } ) =>
    onOnTourPage &&
    css`
      border-color: ${({ theme }) => theme.colors.Primary};
      background-color: ${({ theme }) => theme.colors.Orange};
      &:hover { 
        background-color: white;
        color: ${({ theme }) => theme.colors.Orange};
      }
    `
  };
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  justify-content: center;
  align-items: center;
  align-content: center;
  p {
    color: white;
    font-size: 3em;
    line-height: .8;
    text-align: center;
    &:first-of-type {
      font-size: 10em;
    }
    &:last-of-type {
      text-transform: uppercase;
    }
  }
  ${( { onOnTourPage } ) =>
    onOnTourPage &&
    css`
      p {
        color: ${({ theme }) => theme.colors.Primary};
      }
    `
  };
  /* @media all and (min-width: 700px) {
    p {
      color: ${({ theme }) => theme.colors.Primary};
    }
  } */
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  p {
    color: white;
    font-size: 2.8em;
    text-align: center;
    position: relative;
    line-height: 1;
    &:first-of-type {
      z-index: 3;
    }
    &:last-of-type {
      z-index: 1;
      margin-top: 0.8em;
    }
    &:first-of-type:before {
      content:"@";
      color: ${({ theme }) => theme.colors.Primary};
      font-family: 'Averia Serif Libre', serif;
      font-size: 1.5em;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateY(50%) translateX(-50%);
      z-index: 2;
    }
  }
  @media all and (min-width: 370px) {
    p {
      font-size: 3.2em;
    }
  }
  @media all and (min-width: 700px) {
    /* p {
      color: ${({ theme }) => theme.colors.Primary};
      &:first-of-type:before {
        color: ${({ theme }) => theme.colors.Orange};
      }
    } */
  }
  @media all and (min-width: 800px) {
    p {
      font-size: 3.6em;
    }
  }
  ${( { onOnTourPage } ) =>
    onOnTourPage &&
    css`
      p {
        color: ${({ theme }) => theme.colors.Primary};
        &:first-of-type:before {
          color: ${({ theme }) => theme.colors.Orange};
      }
    `
  };
`;
