import React, { memo, useEffect } from 'react';

import InstagramFeedGrid from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';


import styled, { css } from 'styled-components';
import SectionHeadline from 'components/elements/SectionHeadline';

import PropTypes from 'prop-types';


const InstagramFeed = ({
  events,
  dispatch,
}) => {
  useEffect(() => {
    (function (d, s, id) {
      var js;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://embedsocial.com/cdn/ht.js";
      d.getElementsByTagName("head")[0].appendChild(js);
    }(document, "script", "EmbedSocialHashtagScript"));
  },[]);


  return (
    <Container>
      <StyledHeadline>Social Club</StyledHeadline>
      <div
        class="embedsocial-hashtag"
        data-ref="a5f19f287a76abc697db348b5ed7813f0b5b7d3b"

        data-dynamicload="yes">
      </div>
    </Container>
  )
};

InstagramFeed.propTypes = {
  mapIsOpen: PropTypes.bool
};

export default InstagramFeed;


const Container = styled.section`
  background-color: ${({ theme }) => theme.colors.Primary};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3em 0;
  padding-bottom: 6em;
  .embedsocial-hashtag {
    width: 100%;
  }
`;

const StyledHeadline = styled(SectionHeadline)`
  margin-bottom: 3em;
`;

