import React  from 'react';

import styled from 'styled-components';

import SectionHeadline from 'components/elements/SectionHeadline';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const ProShop = () => {



  return (
    <Container>
      <SectionHeadline greenText>The Pro Shop</SectionHeadline>
      <SubHeadline>Grab your beer. Grab your gear. Let 'er rip.</SubHeadline>
      <LazyLoadImage src="/images/pro-shop-hero.jpg" alt="Image of Golfers enjoying a beer" />
      <CtaLink href="https://proshop.linksbrewing.com/" target="_blank">Shop Now</CtaLink>
    </Container>
  );
};

ProShop.propTypes = {
  
};

export default ProShop;

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  max-width: 700px;
  h2 {
    margin: 0.5em 0;
  }
  @media all and (min-width: 900px) {
    h2 {
      font-size: 4em;
    }
  }

  > img {
    width: 100%; 
    height: auto;
  }
`;

const SubHeadline = styled.h3`
  color: ${({ theme }) => theme.colors.Primary};
  margin: 1em 0;
`;

const CtaLink = styled.a`
  font-family: 'Bebas Neue', serif;
  font-size: 2.4em;
  color: ${({ theme }) => theme.colors.Primary};
  border: 2px solid ${({ theme }) => theme.colors.Primary};
  align-self: center;
  padding: 0.2em 1em;
  padding-top: 0.35em;
  margin: 2em 0;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  &:hover { 
    background-color: ${({ theme }) => theme.colors.Primary};
    color: white;
  }
`;
