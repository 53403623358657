import React  from 'react';

import styled from 'styled-components';


const Splash = () => {



  return (
    <Container>
      <TextContainer>
        <p className={'bebas'}>Play Well.</p>
        <p className={'primary'}>Play Fast.</p>
        <p className={'bebas'}>Play Poorly.</p>
        <p className={'primary'}>Play Faster.</p>
      </TextContainer>
    </Container>
  );
};

Splash.propTypes = {
  
};

export default Splash;


const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('/images/mobile-splash.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 100vw;
  @media all and (min-width: 900px) {
    background-image: url('/images/desktop-splash.jpg');
    height: 51vw;
    background-size: contain;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-size: 2.8em;
    line-height: 1.1;
    text-align: center;
    color: white;
    &.primary {
      color: ${({ theme }) => theme.colors.Primary};
      font-weight: 700;
    }
  }
  @media all and (min-width: 580px) {
    p {
      font-size: 3.6em;
    }
  }
  @media all and (min-width: 750px) {
    p {
      font-size: 4.2em;
    }
  }
  @media all and (min-width: 900px) {
    flex-direction: row;
    p {
      margin: 0 5px;
      &:nth-of-type(2) {
        color: #bdd9f8;
      }
      &:nth-of-type(4) {
        color: #f7d276;
      }
    }
  }
  @media all and (min-width: 1024px) {
    p {
      font-size: 5.2em;
    }
  }
`;

