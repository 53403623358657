import React  from 'react';

import styled from 'styled-components';


const Mission = () => {



  return (
    <Container>
      <Section>
        <p className={'bebas'}>
          <span>We believe</span>
          <span>the links life</span>
          <span>is the only life,</span>
          <span>so we tender</span>
          <span>premium beer</span>
          <span>made just for it.</span>
        </p>
      </Section>
      <Section>
        <img src={'/icons/paddle-icon.svg'} alt="Paddle saying Quiet Please with brewery logo" />
      </Section>
    </Container>
  );
};

Mission.propTypes = {
  
};

export default Mission;


const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media all and (min-width: 650px) {
    flex-direction: row;
  }
`;

const Section = styled.div`
  width: 100%;
  height: 100vw;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  &:first-of-type {
    background-color: ${({ theme }) => theme.colors.Orange};
    background-image: url('/images/mission-beer.jpg');
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: center center;
    p {
      color: white;
      font-size: 4em;
      text-align: center;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 2;
      line-height: 1.1;
      span {
        font-family: inherit;
      }
    }
  }
  &:last-of-type {
    background-color: ${({ theme }) => theme.colors.LtBlue};
    background-image: url('/images/mission-green.jpg');
    background-repeat: no-repeat;
    background-size: 80% auto;
    background-position: center center;
    img {
      width: 50px;
      height: auto;
    }
  }
  @media all and (min-width: 650px) {
    height: 50vw;
  }
  @media all and (min-width: 900px) {
    &:first-of-type {
      p {
        font-size: 5.2em;
      }
    }
    &:last-of-type {
      img {
        width: 70px;
      }
    }
  }
  @media all and (min-width: 1024px) {
    &:first-of-type {
      p {
        font-size: 5.6em;
      }
    }
  }
  @media all and (min-width: 1200px) {
    &:first-of-type {
      p {
        font-size: 6.2em;
      }
    }
    &:last-of-type {
      img {
        width: 85px;
      }
    }
  }
  @media all and (min-width: 1400px) {
    &:first-of-type {
      p {
        font-size: 6.8em;
      }
    }
    &:last-of-type {
      img {
        width: 95px;
      }
    }
  }
`;

