/**
 *
 * IndexPage
 *
 *
 */

import React, { useEffect } from 'react';
import styled from 'styled-components';

import { graphql } from "gatsby";

import Splash from 'components/sections/Splash';
import Manifesto from 'components/sections/Manifesto';
import Mission from 'components/sections/Mission';
import Clubhouse from 'components/sections/Clubhouse';
import OnTour from 'components/sections/OnTour';
import QuietPlease from 'components/sections/QuietPlease';
import ProShop from 'components/sections/ProShop';
import InstagramFeed from 'components/sections/InstagramFeed';


const IndexPage = ({ data }) => {
  useEffect(() => {

  });

  console.log(data);

  const beers = data.clubhouse['nodes'];
  const events = data.events['nodes'];
  const entries = data.quietPlease['nodes'];
  
  return (
    <Container>
      <Splash />
      <Manifesto />
      <Mission />
      <Clubhouse beers={beers} onClubhousePage={false}/>
      <InstagramFeed />
      <QuietPlease isOnBlog={false} entries={entries} />
      <OnTour events={events} onOnTourPage={false}/>
      <ProShop />
    </Container>
  );
};

IndexPage.propTypes = {

};

export default IndexPage;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
`;

export const pageQuery = graphql`
  {
    clubhouse: allCraftClubhouseClubhouseEntry(limit: 1, sort: {fields: lft, order: ASC}) {
      nodes {
        ... on Craft_clubhouse_clubhouse_Entry {
          id
          title
          abv
          descriptor
          beerPrimaryColor
          fontColor
          details
          beerCanImage {
            url
          }
          shopLink
        }
      }
    }
    events: allCraftOnTourOnTourEntry(limit: 3, sort: {order: ASC, fields: startDate}) {
      nodes {
        ... on Craft_onTour_onTour_Entry {
          title
          locationName
          startDate
          endDate
          eventLink
        }
      }
    }
    quietPlease: allCraftQuietPleaseQuietPleaseEntry (limit: 3, sort: {order: DESC, fields: postDate}, filter: {remoteId: {nin: "1815"}}) {
      nodes {
        ... on Craft_quietPlease_quietPlease_Entry {
          id
          title
          slug
          writer
          featuredImage {
            url
          }
        }
      }
    }
  }
`;