import React from 'react';

import styled from 'styled-components';


const Manifesto = () => {



  return (
    <Container>
      <Section>
        <p className={'bebas'}>Welcome to the links.</p>
        <p>The tee is clear.</p>
        <p className={'bebas'}>But before we play.</p>
        <p>Lets raise a beer.</p>
      </Section>
      <img className={'golfers'} src={'/images/golfers.png'} alt="Graphic depicting four golfers"/>
      <Section>
        <p className={'bebas'}>Here's to you, and you, and you.</p>
        <p>At the links you should be.</p>
        <p className={'bebas'}>You have the honors.</p>
        <p>Now, quiet please.</p>
      </Section>
      <img className={'golfers-desktop'} src={'/images/desktop-golfers.png'} alt="Graphic depicting four golfers"/>
    </Container>
  );
};

Manifesto.propTypes = {
  
};

export default Manifesto;


const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  img.golfers,
  img.golfers-desktop {
    width: 100%;
    height: auto;
  }
  img.golfers {
    /* margin-top: -5em; */
  }
  img.golfers-desktop {
    display: none;
  }

  @media all and (min-width: 744px) {
    background-image: url('/images/desktop-clouds.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top center;
    img.golfers {
      display: none;
    }
    img.golfers-desktop {
      display: block;
      /* margin-top: -14em; */
    }
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 5em 0;
  position: relative;
  &:first-of-type {
    margin-bottom: 2em;
    background-image: url('/images/clouds.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;
    @media all and (min-width: 744px) {
      background-image: none;
      margin-bottom: 0;
    }
  }
  &:last-of-type {
    background-color: ${({ theme }) => theme.colors.Primary};
    p {
      color: white;
    }
    @media all and (min-width: 744px) {
      background-color: transparent;
      p {
        color: ${({ theme }) => theme.colors.Primary}; 
      }
    }
  }
  @media all and (min-width: 744px) {
    padding: 2em 0;
  }
  p {
    text-align: center;
    margin: 4px 0;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.Primary};
    font-size: 2.3em;
  }
`;

